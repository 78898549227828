import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  withStyles,
} from "@material-ui/core";
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import React, { useState } from "react";
import styles from "./timeline-jss";
import ImageModal from "./ImageModal";
import UserBadge from "./UserBadge";
import convertDateToRelativeTime from "../../utils/convertDateToRelativeTime";
import { useTranslation } from "react-i18next";
import HashtagUi from "./HashtagUi";
import { ContentState, EditorState } from "draft-js";
import '../../react-draft-wysiwyg.css'

const PostCard = ({ data, classes, theme }) => {
  const { t, i18n } = useTranslation("translation");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (image) => {
    setModalImage(image);
    setModalOpen(true);
  };

  const returnEditorContent = content => {
    const contentBlock = htmlToDraft(content);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return null;
  };
  return (
    <>
      <li key={data._id}>
        <Card className={classes.cardSocmed}>
          <CardHeader
            title={
              <UserBadge
                image={
                  data.school_id
                    ? data.school_id?.avatar
                    : data.tenant_id.avatar
                }
                name={
                  data.school_id
                    ? data.school_id?.title
                    : data.tenant_id.org_name
                }
                time={convertDateToRelativeTime(data.created_at, t, i18n)}
              />
            }
          />
          {Boolean(data.image) && (
            <CardActionArea onClick={() => handleImageClick(data.image)}>
              <CardMedia
                className={classes.media}
                image={data.image}
                title={data.name}
              />
            </CardActionArea>
          )}
          <CardContent>
            {data.content && (
              <div className={classes.descriptions}>
                <Editor
                  editorState={returnEditorContent(data.content)}
                  toolbarHidden
                  editorStyle={{
                    minHeight: '50px',
                    maxHeight: '350px',
                    paddingInline: '5px',
                    marginTop: data.image ? '-15px' : '-35px',
                  }}
                  readOnly
                  textAlignment={theme.direction === 'ltr' ? 'left' : 'right'}
                  hashtag={{
                    separator: ' ',
                    trigger: '#',
                  }}
                />
              </div>
            )}
            {data.hash_tag && data.hash_tag.length && (
              <HashtagUi hashTags={data.hash_tag} />
            )}
          </CardContent>
        </Card>
      </li>
      <ImageModal open={modalOpen} setOpen={setModalOpen} image={modalImage} />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(PostCard);
